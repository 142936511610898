<template>
	<div class="cooperation">
		<headers></headers>
		<el-carousel class="bannerimgs" indicator-position="outside" height="600px">
			<el-carousel-item v-for="item in imglist" :key="item">
				<img style="width: 100%;" :src="item" alt="">
			</el-carousel-item>
		</el-carousel>
		<el-carousel class="phonebannerimgs" indicator-position="outside" height="200px">
			<el-carousel-item v-for="item in imglist2" :key="item">
				<img style="width: 100%;" :src="item" alt="">
			</el-carousel-item>
		</el-carousel>
		<template v-show="mapshow">
			<Maps v-if="pmkd" class="map" :ad_yui="'西安市绿地领海大厦'"></Maps>
			<Mapsphone v-if="!pmkd" class="maps" :ad_yui="'西安市绿地领海大厦'"></Mapsphone>
		</template>
		<!-- <div class="contact">联系我们</div> -->
		<forsubmit></forsubmit>
		<footers></footers>
	</div>
</template>

<script>
	import headers from '../components/header.vue'
	import forsubmit from '../components/forsubmit.vue'
	import footers from '../components/footer.vue'
	import Maps from '../components/map.vue'
	import Mapsphone from '../components/mapsphone.vue'
	export default {
		components: {
			Maps,
			headers,
			forsubmit,
			footers,
			Mapsphone
		},
		data() {
			return {
				screenWidth: document.body.clientWidth,
				pmkd: true,
				mapshow: false,
				imglist: [
					require('../assets/img/c8644a2.png')
				],
				imglist2: [
					require('../assets/img/90b38e98fa91855976d30ef2b935fdf.png')
				],
			}
		},
		watch: {
			screenWidth(val) {
				this.screenWidth = val;
				console.log("this.screenWidth", this.screenWidth)
				if (val > 600) {
					this.pmkd = true
				} else {
					this.pmkd = false
				}
				console.log(this.pmkd)
			}
		},
		created() {
			this.querySortImg()
			var timer1 = window.setTimeout(function() {
				this.mapshow = true
				console.log("4555")
			}, 1000);
		},
		mounted() {
			const that = this
			let l = document.body.clientWidth
			if (l > 600) {
				this.pmkd = true
			} else {
				this.pmkd = false
			}
			window.onresize = () => {
				return (() => {
					window.screenWidth = document.body.clientWidth;
					that.screenWidth = window.screenWidth;
				})();
			}
			
		},
		methods: {
			querySortImg(){
				let gh = []
				this.$post('querySortImg',{
					sortHead:5,
					sortOrder:0,
					sortFrom:0
				}).then((res)=>{
					if(res&&res.length>0){
						this.imglist = []
						this.imglist2 = []
						res.forEach((e,m)=>{
							let h = e.sortImg.split(',')
							h.forEach((v,b)=>{
								if(e.sortSign == 0){
									this.imglist.push(v)
								}else{
									gh.push(v)
								}
							})
						})
						if(!gh||gh.length<=0){
							this.imglist.forEach((f,g)=>{
								f = f+'?x-bce-process=image/crop,x_500,y_200,w_701,h_400'
								this.imglist2.push(f)
							})
						}else{
							this.imglist2 = gh
						}
					}else{
						
					}
				})
				this.$nextTick(()=>{
					
				})
			}
		}
	}
</script>

<style scoped lang="less">
	.cooperation {
		
		.contact {
			width: 1200px;
			font-size: 20px;
			margin: 30px auto 60px auto;

			position: relative;
		}

		.contact::after {
			content: '';
			display: block;
			position: absolute;
			bottom: -10px;
			left: 0;
			width: 10%;
			height: 2px;
			background-color: #4a8ea3;
		}
		.phonebannerimgs{
			display: none;
		}
		

	}

	@media screen and (max-width:900px) {
		
		.cooperation {
			.contact {
				width: 100%;
				padding: 0 0 0 10px;
				font-size: 18px;
			}

			.contact::after {
				left: 10px;
			}

			.bannerimgs {
				display: none;
			}
			
			.phonebannerimgs {
				display: block;
				margin-top: 64px;
			}
		}


	}
</style>
